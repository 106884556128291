import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Slide, Grid, Typography, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

// icons:
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import DirectionsOutlinedIcon from '@material-ui/icons/DirectionsOutlined';

const HideOnScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const useStyles = makeStyles((theme) => ({
  navLink: {
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      transition: 'transform .2s, color 0.3s',
      transform: 'scale(1.1)',
      color: theme.palette.secondary.main,
    }
  },
  navIcon: {
    '&:hover': {
      transition: 'transform .2s',
      transform: 'scale(1.1)',
      color: theme.palette.secondary.main,
    }
  },
  textField: {
    [theme.breakpoints.down("md")]: {
      fontSize: '.8em'
    },
    [theme.breakpoints.down(800)]: {
      fontSize: '.6em'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  icon: {
    [theme.breakpoints.down("md")]: {
      fontSize: '1.25em'
    },
    [theme.breakpoints.down(800)]: {
      fontSize: '1em'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  mobileMenu: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();

  return (
    <HideOnScroll {...props}>
      <AppBar>
        <Toolbar>
          <Grid container spacing={1}>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <MenuOutlinedIcon className={classes.mobileMenu} style={{ cursor: 'pointer' }} onClick={() => props.openMobileMenu()} />
              <NavLink className={classes.navLink} to="/home">
                <Typography className={classes.textField}>Начало</Typography>
              </NavLink>
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <NavLink className={classes.navLink} to="/booking">
                <Typography className={classes.textField}>Настаняване</Typography>
              </NavLink>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <Typography className={`${classes.navLink} ${classes.textField}`} onClick={() => { props.onOpenHouseRules() }}>Правила на Къщата</Typography>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
              <NavLink className={classes.navLink} to="/landmarks">
                <Typography className={classes.textField}>Забележителности</Typography>
              </NavLink>
            </Grid>

            <Grid item xs={1}>
              <CallOutlinedIcon className={`${classes.icon} ${classes.navIcon}`} style={{ cursor: 'pointer' }} onClick={() => { props.onOpenContactUs() }} />
            </Grid>
            <Grid item xs={1}>
              <DirectionsOutlinedIcon className={`${classes.icon} ${classes.navIcon}`} style={{ cursor: 'pointer' }} onClick={() => { window.open('https://www.google.bg/maps/place/Sunday+Guest+House+%2F+%D0%9A%D1%8A%D1%89%D0%B0+%D0%B7%D0%B0+%D0%B3%D0%BE%D1%81%D1%82%D0%B8+%D0%A1%D1%8A%D0%BD%D0%B4%D0%B5%D0%B9/@43.3879901,23.2169653,17z/data=!4m18!1m9!3m8!1s0x40ab334595555555:0x5579283d6a5f4c18!2sFamily+Hotel+Ring!5m2!4m1!1i2!8m2!3d43.3893003!4d23.2169274!3m7!1s0x40ab330ca425d40f:0x9c5a9e2241663bdc!5m2!4m1!1i2!8m2!3d43.3873325!4d23.2161702', '_blanc'); }} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default Header;