import { BottomNavigation, BottomNavigationAction, Grid, Typography, withTheme, makeStyles } from "@material-ui/core";
import { Facebook, Instagram } from 'react-bootstrap-icons';

const useStyles = makeStyles((theme) => ({
  textField: {
    [theme.breakpoints.down(1024)]: {
      fontSize: '.8em'
    },
    [theme.breakpoints.down(850)]: {
      fontSize: '.5em'
    },
  },
  textFieldSecondary: {
    [theme.breakpoints.down(1024)]: {
      fontSize: '.8em'
    },
    [theme.breakpoints.down(950)]: {
      display: 'none'
    },
  },
  icon: {
    [theme.breakpoints.down(1024)]: {
      fontSize: '.5em'
    },
  },
}));

const Footer = (props) => {
  const classes = useStyles();

  return (
    <BottomNavigation style={{ backgroundColor: props.theme.palette.primary.main }}>
      <Grid container spacing={0}>
        <Grid item xs={8}>
          <Typography className={classes.textField} variant="body2" color="textSecondary" style={{ paddingLeft: '1em', paddingTop: '0.7em' }}>© 2021 Къща за гости „Съндей“. Всички права запазени.</Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'right' }} className={classes.textFieldSecondary}>
          <Typography className={classes.textFieldSecondary} variant="body2" color="textSecondary" style={{ paddingTop: '0.7em' }}>Последвайте ни:</Typography>
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'right' }}>
          <BottomNavigationAction className={classes.icon} icon={<Facebook size='2.4em' style={{ cursor: 'pointer' }} onClick={() => { window.open('https://www.facebook.com/SundayGuestHouse') }} />} />
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'right' }}>
          <BottomNavigationAction className={classes.icon} icon={<Instagram size='2.4em' style={{ cursor: 'pointer' }} onClick={() => { window.open('https://www.instagram.com/sundayguesthouse/') }} />} />
        </Grid>
      </Grid>
    </BottomNavigation>
  );
};

export default withTheme(Footer);