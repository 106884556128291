import { Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { modals } from '../../data/text.js';

// Icons
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { Facebook } from 'react-bootstrap-icons';
import { Instagram } from 'react-bootstrap-icons';

export default function ScrollDialog(props) {
  return (

    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={'body'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Контакти</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography style={{ display: 'flex', align: 'center' }}><PhoneInTalkOutlinedIcon />&nbsp;{modals.contactUs.phone}</Typography>
          <Typography style={{ display: 'flex', align: 'center' }}><EmailOutlinedIcon />&nbsp;{modals.contactUs.email}</Typography>
          <Grid style={{ paddingTop: '5%', textAlign: 'center' }} container>
            <Grid item xs={6}>
              <Facebook style={{ cursor: 'pointer', fontSize: '1.5em' }} onClick={() => { window.open('https://www.facebook.com/SundayGuestHouse') }} />
            </Grid>
            <Grid item xs={6}>
              <Instagram style={{ cursor: 'pointer', fontSize: '1.5em' }} onClick={() => { window.open('https://www.instagram.com/sundayguesthouse/') }} />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}