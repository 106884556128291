
import 'date-fns';
import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Checkbox, InputLabel, makeStyles, MenuItem, Select, Typography, withTheme } from '@material-ui/core';
import { useContext } from 'react';
import { BookingModalContext } from '../Layout/Layout';
import { functions } from '../../App';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  rulesLink: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    boxShadow: `0 3px ${theme.palette.secondary.main}`,
    transition: 'background 0.1s, box-shadow 0.3s, color 0.1s',
    '&:hover': {
      color: 'white',
      background: theme.palette.secondary.main,
      boxShadow: '0px 10px 10px 0px gray',
    }
  },
  rules: {
    [theme.breakpoints.up(710)]: {
      paddingLeft: '20%',
      fontSize: '1em'
    },
    [theme.breakpoints.down(710)]: {
      paddingLeft: '15%',
      fontSize: '.7em'
    },
    [theme.breakpoints.down(550)]: {
      paddingLeft: '5%',
    },
    [theme.breakpoints.down(450)]: {
      paddingLeft: '2%',
      fontSize: '.6em'
    }
  },
  layout: {
    [theme.breakpoints.down(610)]: {
      display: 'none'
    },
  },
  smallLayout: {
    [theme.breakpoints.up(610)]: {
      display: 'none'
    },
  },
}));

const BookingModal = (props) => {
  const classes = useStyles();

  const [selectedDate1, setSelectedDate1] = useState(new Date());
  const tomorrow = new Date(selectedDate1);
  tomorrow.setDate(selectedDate1.getDate() + 1);
  const [selectedDate2, setSelectedDate2] = useState(tomorrow);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [clients, setClients] = useState('');
  const [kids, setKids] = useState('');
  const [selectedService, setSelectedService] = useState('СТАЯ');
  const [isChecked, setIsChecked] = useState(false);

  const [errors, setErrors] = useState({
    name: null,
    surname: null,
    phone: null,
    email: null,
    clients: null,
    kids: null
  })

  const [, setHouseRulesOpen] = useContext(BookingModalContext);

  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };

  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  };

  const handleNameChange = (name) => {
    setName(name);


  };

  const handleSurnameChange = (surname) => {
    setSurname(surname);
  };

  const handlePhoneChange = (phone) => {
    setPhone(phone);
  };

  const handleEmailChange = (email) => {
    setEmail(email);
  };

  const handleClientsChange = (clientsNumber) => {
    setClients(clientsNumber);
  };

  const handleKidsChange = (kidsNumber) => {
    setKids(kidsNumber);
  };

  const handleServiceChange = (service) => {
    setSelectedService(service);
  };

  const validate = async () => {
    const emailRegex = /^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const nameErr = name.trim() === ''
    const surnameErr = surname.trim() === ''
    const phoneErr = phone.trim() === '' || phone.trim().length > 15 || phone.trim().length < 10
    const emailErr = !emailRegex.test(email.trim())
    const clientsErr = clients.trim() === '' || +(clients.trim()) < 0
    const kidsErr = kids.trim() === '' || +(kids.trim()) <= 0

    await setErrors({
      name: nameErr,
      surname: surnameErr,
      phone: phoneErr,
      email: emailErr,
      clients: clientsErr,
      kids: kidsErr,
    })

    return nameErr || surnameErr || phoneErr || emailErr || clientsErr || kidsErr
  }

  const submitForm = async () => {
    const res = await validate()
    if (!res) {
      const callable = functions.httpsCallable('sendEmails');

      callable({
        name: name,
        surname: surname,
        phone: phone,
        email: email,
        clients: clients,
        kids: kids,
        selectedDate1: selectedDate1.toLocaleDateString('en-GB'),
        selectedDate2: selectedDate2.toLocaleDateString('en-GB'),
        selectedService
      })
        .then(({ error }) => {
          if (!error) {
            toast.success('Запитването е изпратено')
          } else {
            toast.error('Възникна грешка')
          }
        })

      props.onClose()
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle id="scroll-dialog-title" style={{ backgroundColor: props.theme.palette.secondary.main }}>Изпрати запитване</DialogTitle>
      <DialogContent>
        <DialogActions>
          <Grid container spacing={5} className={classes.layout}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={6} className={classes.layout}>
                <KeyboardDatePicker
                  label="От:"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-from"
                  value={selectedDate1}
                  onChange={handleDateChange1}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item xs={6} className={classes.layout}>
                <KeyboardDatePicker
                  label="До:"
                  margin="normal"
                  id="date-picker-to"
                  format="MM/dd/yyyy"
                  value={selectedDate2}
                  onChange={handleDateChange2}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={6} className={classes.layout} onChange={(event) => handleNameChange(event.target.value)}>
              <TextField error={errors.name} helperText={errors.name ? 'Моля въведете име' : ''} id="tf-name" required label="Име" />
            </Grid>
            <Grid item xs={6} className={classes.layout} onChange={(event) => handleSurnameChange(event.target.value)}>
              <TextField error={errors.surname} helperText={errors.name ? 'Моля въведете фамилия' : ''} id="tf-familyName" required label="Фамилия" />
            </Grid>
            <Grid item xs={6} className={classes.layout} onChange={(event) => handlePhoneChange(event.target.value)}>
              <TextField error={errors.phone} helperText={errors.name ? 'Моля въведете телефон' : ''} id="tf-phone" required label="Телефон" />
            </Grid>
            <Grid item xs={6} className={classes.layout} onChange={(event) => handleEmailChange(event.target.value)}>
              <TextField error={errors.email} helperText={errors.name ? 'Моля въведете имейл' : ''} id="tf-email" required label="Електронна поща" />
            </Grid>
            <Grid item xs={6} className={classes.layout} onChange={(event) => handleClientsChange(event.target.value)}>
              <TextField error={errors.clients} helperText={errors.name ? 'Моля въведете брой' : ''} id="tf-numberOfVisitors" required type="number" label="Брой посетители" />
            </Grid>
            <Grid item xs={6} className={classes.layout} onChange={(event) => handleKidsChange(event.target.value)}>
              <TextField error={errors.kids} helperText={errors.name ? 'Моля въведете брой' : ''} id="tf-numberOfKids" required type="number" label="Брой деца" />
            </Grid>
            <Grid item xs={6} className={classes.layout}>
              <InputLabel id="demo-simple-select-label">Изберете услуга</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedService}
                onChange={(event) => handleServiceChange(event.target.value)}
              >
                <MenuItem value={'СТАЯ'}>Стая</MenuItem>
                <MenuItem value={'АПАРТАМЕНТ'}>Апартамент</MenuItem>
                <MenuItem value={'ЦЯЛА КЪЩА'}>Цялата Къща</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6} className={classes.layout}></Grid>

            <Grid item xs={12} className={classes.layout}>
              <Typography className={classes.rules}>Прочетох&nbsp;
                <span className={classes.rulesLink} onClick={() => setHouseRulesOpen(true)}>
                  условията на къщата
                </span>
                <Checkbox
                  checked={isChecked}
                  color="secondary"
                  onChange={() => setIsChecked(!isChecked)}
                />
              </Typography>
            </Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={4}><Button disabled={!isChecked} style={{ backgroundColor: props.theme.palette.secondary.main }} onClick={() => submitForm()}>Изпрати запитване</Button></Grid>
          </Grid>

          {/* Layout for small displays */}

          <Grid container spacing={5} className={classes.smallLayout}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} className={classes.smallLayout}>
                <KeyboardDatePicker
                  label="От:"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-from-small"
                  value={selectedDate1}
                  onChange={handleDateChange1}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.smallLayout}>
                <KeyboardDatePicker
                  label="До:"
                  margin="normal"
                  id="date-picker-to-small"
                  format="MM/dd/yyyy"
                  value={selectedDate2}
                  onChange={handleDateChange2}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={12} className={classes.smallLayout} onChange={(event) => handleNameChange(event.target.value)}>
              <TextField required label="Име" />
            </Grid>
            <Grid item xs={12} className={classes.smallLayout} onChange={(event) => handleSurnameChange(event.target.value)}>
              <TextField required label="Фамилия" />
            </Grid>
            <Grid item xs={12} className={classes.smallLayout} onChange={(event) => handlePhoneChange(event.target.value)}>
              <TextField required label="Телефон" />
            </Grid>
            <Grid item xs={12} className={classes.smallLayout} onChange={(event) => handleEmailChange(event.target.value)}>
              <TextField required label="Електронна поща" />
            </Grid>
            <Grid item xs={12} className={classes.smallLayout} onChange={(event) => handleClientsChange(event.target.value)}>
              <TextField required type="number" label="Брой посетители" />
            </Grid>
            <Grid item xs={12} className={classes.smallLayout} onChange={(event) => handleKidsChange(event.target.value)}>
              <TextField required type="number" label="Брой деца" />
            </Grid>
            <Grid item xs={12} className={classes.smallLayout}>
              <InputLabel id="demo-simple-select-label">Изберете услуга</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedService}
                onChange={(event) => handleServiceChange(event.target.value)}
              >
                <MenuItem value={'room'}>Стая</MenuItem>
                <MenuItem value={'apartment'}>Апартамент</MenuItem>
                <MenuItem value={'house'}>Цялата Къща</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} className={classes.smallLayout}>
              <Typography className={classes.rules}>Прочетох&nbsp;
                <span className={classes.rulesLink} onClick={() => setHouseRulesOpen(true)}>
                  условията на къщата
                </span>
                <Checkbox
                  checked={isChecked}
                  color="secondary"
                  onChange={() => setIsChecked(!isChecked)}
                />
              </Typography>
            </Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={4}><Button disabled={!isChecked} style={{ backgroundColor: props.theme.palette.secondary.main }} onClick={() => submitForm()}>Изпрати запитване</Button></Grid>
          </Grid>
        </DialogActions>
      </DialogContent>
    </Dialog >
  );
};

export default withTheme(BookingModal);
