import { makeStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

// Icons
import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined';
import KingBedOutlinedIcon from '@material-ui/icons/KingBedOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import { useContext } from 'react';
import { BookingModalContext } from '../Layout/Layout';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: '5%',
    padding: '.5%',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      transition: 'transform .2s, color 0.3s',
      transform: 'scale(1.1)',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    }
  },
  bookingPaper: {
    padding: '2%', 
    backgroundColor: theme.palette.secondary.main, 
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    '&:hover': {
      transition: 'transform .2s',
      transform: 'scale(1.05)',
    }
  },
  container: {
    [theme.breakpoints.up(900)]: {
      width: '80%',
      paddingLeft: '10%',
      paddingTop: '5%',
      paddingBottom: '5%',
    },
    [theme.breakpoints.down(900)]: {
      paddingTop: '5%',
    },
  },
  root: {
    minWidth: 275,
    textAlign: 'center',
  },
  gridLarge: {
    [theme.breakpoints.down(1100)]: {
      display: 'none'
    },
  },
  gridMedium: {
    [theme.breakpoints.up(1100)]: {
      display: 'none'
    },
    [theme.breakpoints.down(750)]: {
      display: 'none'
    },
  },
  gridSmall: {
    [theme.breakpoints.up(750)]: {
      display: 'none'
    },
  },
  gridMediumHouse: {
    [theme.breakpoints.up(1100)]: {
      display: 'none'
    },
  },
  gridMediumPadding: {
    [theme.breakpoints.up(1100)]: {
      display: 'none'
    },
    [theme.breakpoints.down(750)]: {
      display: 'none'
    },
  },
  gridSmallPaddings: {
    [theme.breakpoints.up(750)]: {
      display: 'none'
    },
    [theme.breakpoints.down(500)]: {
      display: 'none'
    },
  },
  gridExtraSmallPaddings: {
    [theme.breakpoints.up(500)]: {
      display: 'none'
    },
  }
}));

const Booking = (props) => {
  const classes = useStyles();

  const [setBookingOpen] = useContext(BookingModalContext);

  return (
    <>
      <div id="back-to-top-anchor"></div>
      <div className={classes.container}>
        <Grid container spacing={3} style={{ marginTop: '5%' }}>
          <Grid item xs={4} className={classes.gridLarge} >
            <Paper className={`${classes.root} ${classes.bookingPaper}`}>
              <HotelOutlinedIcon fontSize="large" />
              <Typography variant="h5">Единична стая</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>70</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>
          <Grid item xs={4} className={classes.gridLarge}>
            <Paper className={`${classes.root} ${classes.bookingPaper}`}>
              <HotelOutlinedIcon fontSize="large" />
              <Typography variant="h5">Двойна стая</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>100</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>
          <Grid item xs={4} className={classes.gridLarge}>
            <Paper className={`${classes.root} ${classes.bookingPaper}`}>
              <KingBedOutlinedIcon fontSize="large" />
              <Typography variant="h5">Апартамент</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>120</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>

          <Grid item xs={6} style={{ paddingTop: '10%' }} className={classes.gridLarge}>
            <Paper className={`${classes.root} ${classes.bookingPaper}`}>
              <TodayOutlinedIcon fontSize="large" />
              <Typography variant="h5">Цялата къща</Typography>
              <Typography variant="body1">( Делнични дни )</Typography>
              <br />
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>650</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: '10%' }} className={classes.gridLarge}>
            <Paper className={`${classes.root} ${classes.bookingPaper}`}>
              <InsertInvitationOutlinedIcon fontSize="large" />
              <Typography variant="h5">Цялата къща уикенд</Typography>
              <Typography variant="body1">( петък, събота ) и Празнични дни</Typography>
              <br />
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>750</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>

          {/* Grid Layout for Medium Size Screens */}

          <Grid item xs={1} className={classes.gridMedium}></Grid>
          <Grid item xs={4} className={classes.gridMedium} >
            <Paper  className={`${classes.root} ${classes.bookingPaper}`}>
              <HotelOutlinedIcon fontSize="large" />
              <Typography variant="h5">Единична стая</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>70</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>
          <Grid item xs={2} className={classes.gridMedium}></Grid>
          <Grid item xs={4} className={classes.gridMedium}>
            <Paper  className={`${classes.root} ${classes.bookingPaper}`}>
              <HotelOutlinedIcon fontSize="large" />
              <Typography variant="h5">Двойна стая</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>100</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>
          <Grid item xs={3} className={classes.gridMedium}></Grid>
          <Grid item xs={6} className={classes.gridMedium}>
            <Paper  className={`${classes.root} ${classes.bookingPaper}`}>
              <KingBedOutlinedIcon fontSize="large" />
              <Typography variant="h5">Апартамент</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>120</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>
          <Grid item xs={3} className={classes.gridMedium}></Grid>

          {/* Grid Layout for Small Size Displays */}

          <Grid item xs={3} className={classes.gridSmallPaddings}></Grid>
          <Grid item xs={2} className={classes.gridExtraSmallPaddings}></Grid>
          <Grid item xs={6} className={classes.gridSmall} >
            <Paper  className={`${classes.root} ${classes.bookingPaper}`}>
              <HotelOutlinedIcon fontSize="large" />
              <Typography variant="h5">Единична стая</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>70</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>
          <Grid item xs={3} className={classes.gridSmallPaddings}></Grid>
          <Grid item xs={4} className={classes.gridExtraSmallPaddings}></Grid>
          <Grid item xs={3} className={classes.gridSmallPaddings}></Grid>
          <Grid item xs={2} className={classes.gridExtraSmallPaddings}></Grid>
          <Grid item xs={6} className={classes.gridSmall}>
            <Paper  className={`${classes.root} ${classes.bookingPaper}`}>
              <HotelOutlinedIcon fontSize="large" />
              <Typography variant="h5">Двойна стая</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>100</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>
          <Grid item xs={3} className={classes.gridSmallPaddings}></Grid>
          <Grid item xs={4} className={classes.gridExtraSmallPaddings}></Grid>
          <Grid item xs={3} className={classes.gridSmallPaddings}></Grid>
          <Grid item xs={2} className={classes.gridExtraSmallPaddings}></Grid>
          <Grid item xs={6} className={classes.gridSmall}>
            <Paper  className={`${classes.root} ${classes.bookingPaper}`}>
              <KingBedOutlinedIcon fontSize="large" />
              <Typography variant="h5">Апартамент</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>120</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>
          <Grid item xs={3} className={classes.gridSmallPaddings}></Grid>
          <Grid item xs={4} className={classes.gridExtraSmallPaddings}></Grid>



          <Grid item xs={3} className={classes.gridMediumPadding}></Grid>
          <Grid item xs={3} className={classes.gridSmallPaddings}></Grid>
          <Grid item xs={2} className={classes.gridExtraSmallPaddings}></Grid>
          <Grid item xs={6} style={{ paddingTop: '10%' }} className={classes.gridMediumHouse}>
            <Paper  className={`${classes.root} ${classes.bookingPaper}`}>
              <TodayOutlinedIcon fontSize="large" />
              <Typography variant="h5">Цялата къща</Typography>
              <Typography variant="body1">( Делнични дни )</Typography>
              <br />
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>650</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>
          <Grid item xs={3} className={classes.gridMediumPadding}></Grid>
          <Grid item xs={3} className={classes.gridSmallPaddings}></Grid>
          <Grid item xs={4} className={classes.gridExtraSmallPaddings}></Grid>

          <Grid item xs={3} className={classes.gridMediumPadding}></Grid>
          <Grid item xs={3} className={classes.gridSmallPaddings}></Grid>
          <Grid item xs={2} className={classes.gridExtraSmallPaddings}></Grid>
          <Grid item xs={6} className={classes.gridMediumHouse}>
            <Paper  className={`${classes.root} ${classes.bookingPaper}`}>
              <InsertInvitationOutlinedIcon fontSize="large" />
              <Typography variant="h5">Цялата къща уикенд</Typography>
              <Typography variant="body1">( петък, събота ) и Празнични дни</Typography>
              <br />
              <Typography variant="body2" style={{ display: 'inline' }}>BGN </Typography>
              <Typography variant="h3" style={{ display: 'inline' }}>750</Typography>
              <Typography variant="body2" style={{ display: 'inline' }}> / вечер</Typography>
              <br />
              <Button className={classes.button} size="small" onClick={() => { setBookingOpen(true) }}><DateRangeOutlinedIcon /> Изпрати запитване</Button>
            </Paper>
          </Grid>
          <Grid item xs={3} className={classes.gridMediumPadding}></Grid>
          <Grid item xs={4} className={classes.gridSmallPaddings}></Grid>
          <Grid item xs={4} className={classes.gridExtraSmallPaddings}></Grid>
        </Grid>
      </div>
    </>
  );
};

export default withTheme(Booking);
