import Header from './Header';
import React from 'react';
import { useState } from 'react';
import HouseRulesModal from '../modals/HouseRules';
import ContactUsModal from '../modals/ContactUs';
import BookingModal from '../modals/Booking.jsx';
import Footer from './Footer';
import { Fab, SwipeableDrawer } from '@material-ui/core';
import MobileMenu from '../MobileMenu';
import ScrollTop from '../ScrollTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';

export const BookingModalContext = React.createContext();

const Layout = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [houseRulesOpen, setHouseRulesOpen] = useState(false);
  const [contactUsOpen, setContactUsOpen] = useState(false);
  const [bookingOpen, setBookingOpen] = useState(false);

  const handleCloseHouseRules = () => {
    setHouseRulesOpen(false);
  };

  const handleCloseContactUs = () => {
    setContactUsOpen(false);
  };

  const handleCloseBooking = () => {
    setBookingOpen(false);
  };

  const toggleMobileMenu = (open) => {
    setMobileMenuOpen(open);
  };

  return (
    <>
      <Header
        openMobileMenu={() => toggleMobileMenu(true)}
        onOpenHouseRules={() => setHouseRulesOpen(true)}
        onOpenContactUs={() => setContactUsOpen(true)}
      />

      <BookingModalContext.Provider value={[setBookingOpen, setHouseRulesOpen]}>
        <div className="container">
          {props.children}

          <ScrollTop {...props} style={{}}>
            <Fab color="primary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpOutlinedIcon />
            </Fab>
          </ScrollTop>

          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
        </div>

        <SwipeableDrawer
          open={mobileMenuOpen}
          onClose={() => toggleMobileMenu(false)}
          onOpen={() => toggleMobileMenu(true)}
        >
          <MobileMenu
            toggleMobileMenu={toggleMobileMenu}
            onOpenContactUs={() => setContactUsOpen(true)}
            onOpenHouseRules={() => setHouseRulesOpen(true)}
          />
        </SwipeableDrawer>

        <HouseRulesModal
          open={houseRulesOpen}
          onClose={handleCloseHouseRules}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
        </HouseRulesModal>

        <ContactUsModal
          open={contactUsOpen}
          onClose={handleCloseContactUs}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
        </ContactUsModal>

        <BookingModal
          open={bookingOpen}
          onClose={handleCloseBooking}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
        </BookingModal>
      </BookingModalContext.Provider>
      <Footer />
    </>
  );
};

export default Layout;
