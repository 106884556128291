import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import ImageSlider from '../ImageSlider';
import { text } from '../../data/text.js';
import Gallery from '../Gallery';

// Icons
import LocalParkingOutlinedIcon from '@material-ui/icons/LocalParkingOutlined';
import KitchenOutlinedIcon from '@material-ui/icons/KitchenOutlined';
import WifiOutlinedIcon from '@material-ui/icons/WifiOutlined';
import PoolOutlinedIcon from '@material-ui/icons/PoolOutlined';
import ChildCareOutlinedIcon from '@material-ui/icons/ChildCareOutlined';
import BeachAccessOutlinedIcon from '@material-ui/icons/BeachAccessOutlined';
import { useContext } from 'react';
import { BookingModalContext } from '../Layout/Layout.jsx';
import DateRangeOutlined from '@material-ui/icons/DateRangeOutlined';

const useStyles = makeStyles((theme) => ({
  bookingButton: {
    [theme.breakpoints.up(1000)]: {
      position: 'absolute',
      textAlign: 'center',
      width: '100%',
      top: '60%',
      color: theme.palette.secondary.main
    },
    [theme.breakpoints.down(1000)]: {
      display: 'none'
    },
  },
  bookingButtonSmall: {
    [theme.breakpoints.up(1000)]: {
      display: 'none',
    },
    [theme.breakpoints.down(1000)]: {
      backgroundColor: theme.palette.secondary.main,
      paddingTop: '10%',
      paddingBottom: '10%',
      textAlign: 'center',
      width: '100%',
      color: theme.palette.primary.main
    },
  },
  button: {
    marginTop: '5%',
    padding: '.5%',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      transition: 'transform .2s, color 0.3s',
      transform: 'scale(1.1)',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    }
  },
  buttonSmall: {
    marginTop: '5%',
    padding: '.5%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      transition: 'transform .2s, color 0.3s',
      transform: 'scale(1.1)',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    }
  }
}));

const Home = (props) => {
  const [setBookingOpen] = useContext(BookingModalContext);

  const classes = useStyles();
  return (
    <>
      <br />
      <div className="home-slideshow" id="back-to-top-anchor" style={{ position: 'relative', height: '100%' }}>
        <ImageSlider />

        <div className={classes.bookingButton}>
          <Button
            size="small"
            className={classes.button}
            onClick={() => { setBookingOpen(true) }}
          ><DateRangeOutlined /> Изпрати запитване</Button>
        </div>
      </div>

      <div className={classes.bookingButtonSmall}>
        <Typography variant='h5'>Къща за гости</Typography>
        <Typography variant='h4'>Съндей</Typography>
        <Button
          size="small"
          className={classes.buttonSmall}
          onClick={() => { setBookingOpen(true) }}
        ><DateRangeOutlined /> Изпрати запитване</Button>
      </div>

      <div style={{ paddingTop: '5%', paddingBottom: '5%', backgroundColor: props.theme.palette.secondary.main, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
        <div style={{ width: '80%', paddingLeft: '10%' }}>
          <Typography style={{ textAlign: 'justify' }} className="home-text" variant="body1">{text.intro}</Typography>
        </div>
      </div>
      <div style={{ width: '80%', paddingLeft: '10%' }}>
        <Grid container spacing={10} style={{ textAlign: 'center', marginTop: '5%' }}>
          <Grid item xs={6}>
            <KitchenOutlinedIcon fontSize='large' />
            <Typography color="textSecondary">{text.kitchen}</Typography>
          </Grid>

          <Grid item xs={6}>
            <PoolOutlinedIcon fontSize='large' />
            <Typography color="textSecondary">{text.pool}</Typography>
          </Grid>

          <Grid item xs={6}>
            <ChildCareOutlinedIcon fontSize='large' />
            <Typography color="textSecondary">{text.children}</Typography>
          </Grid>

          <Grid item xs={6}>
            <WifiOutlinedIcon fontSize='large' />
            <Typography color="textSecondary">{text.wifi}</Typography>
          </Grid>

          <Grid item xs={4}></ Grid>

          <Grid item xs={4}>
            <LocalParkingOutlinedIcon fontSize='large' />
            <Typography color="textSecondary">{text.parking}</Typography>
          </Grid>

          <Grid item xs={4}></Grid>

          <Grid item xs={12}>
            <BeachAccessOutlinedIcon fontSize='large' />
            <Typography color="textSecondary">{text.overall}</Typography>
          </Grid>
        </Grid>
      </div>

      <div className="homeGallery" style={{ marginTop: '5%', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
        <Gallery />
      </div>
    </>
  );
};

export default withTheme(Home);
