import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import { modals } from '../../data/text.js';

const HouseRules = (props) => {
  return (

    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={'body'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle id="scroll-dialog-title">Вътрешни Правила:</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">{modals.rules.intro}</Typography>
          <Typography variant="body2">{modals.rules.please}</Typography>
          <br />
          <Typography variant="body1">{modals.rules.reservationsTitle}</Typography>
          <Typography variant="body2">{modals.rules.reservationsIn}</Typography>
          <Typography variant="body2">{modals.rules.reservationsOut}</Typography>
          <Typography variant="body2">{modals.rules.reservations}</Typography>
          <br />
          <Typography variant="body1">{modals.rules.rulesTitle}</Typography>
          <Typography variant="body2">{modals.rules.rules1}</Typography>
          <Typography variant="body2">{modals.rules.rules2}</Typography>
          <Typography variant="body2">{modals.rules.rules3}</Typography>
          <Typography variant="body2">{modals.rules.rules4}</Typography>
          <Typography variant="body2">{modals.rules.rules5}</Typography>
          <Typography variant="body2">{modals.rules.rules6}</Typography>
          <Typography variant="body2">{modals.rules.rules7}</Typography>
          <Typography variant="body2">{modals.rules.rules8}</Typography>
          <Typography variant="body2">{modals.rules.rules9}</Typography>
          <Typography variant="body2">{modals.rules.rules10}</Typography>
          <Typography variant="body2">{modals.rules.rules11}</Typography>
          <Typography variant="body2">{modals.rules.rules12}</Typography>
          <Typography variant="body2">{modals.rules.rules13}</Typography>
          <br />
          <Typography variant="body1">{modals.rules.paymentsTitle}</Typography>
          <Typography variant="body2">{modals.rules.paymentsText}</Typography>
          <Typography variant="body2">{modals.rules.payments1}</Typography>
          <Typography variant="body2">{modals.rules.payments2}</Typography>
          <br />
          <Typography variant="body1">{modals.rules.valuablesTitle}</Typography>
          <Typography variant="body2">{modals.rules.valuablesText}</Typography>
          <br />
          <Typography variant="body1">{modals.rules.cancellationTitle}</Typography>
          <Typography variant="body2">{modals.rules.cancellationText}</Typography>
          <br />
          <Typography variant="body1">{modals.rules.acceptance}</Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default HouseRules;