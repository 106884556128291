import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

import { homePics } from '../data/pics.js'

const ImageSlider = () => {
  return (
    <Splide
      options={{
        type: 'loop',
        gap: '1rem',
        autoplay: true,
        pauseOnHover: false,
        resetProgress: false,
        arrows: 'slider',
        interval: 7000,
        height: '50%'
      }}
      hasSliderWrapper
    >
      {homePics.map(pic => {
        return (
          <SplideSlide>
            <img src={pic} style={{ width: '100%' }} alt="Home pic" />
          </SplideSlide>
        )
      })}
    </Splide>
  );
};

export default ImageSlider;
