import { List, ListItem, ListItemIcon, ListItemText, } from '@material-ui/core';

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import DirectionsOutlinedIcon from '@material-ui/icons/DirectionsOutlined';
import { useHistory } from 'react-router-dom';

const MobileMenu = (props) => {
  const history = useHistory();

  return (
    <div
      role="presentation"
      onClick={() => props.toggleMobileMenu(false)}
      onKeyDown={() => props.toggleMobileMenu(false)}
    >
      <List>
        <ListItem button onClick={() => { history.push('/home') }}>
          <ListItemIcon><HomeOutlinedIcon /></ListItemIcon>
          <ListItemText primary="Начало" />
        </ListItem>
        <ListItem button onClick={() => { history.push('/booking') }}>
          <ListItemIcon><EventAvailableOutlinedIcon /></ListItemIcon>
          <ListItemText primary="Настаняване" />
        </ListItem>
        <ListItem button onClick={() => { props.onOpenHouseRules() }}>
          <ListItemIcon><GavelOutlinedIcon /></ListItemIcon>
          <ListItemText primary="Правила" />
        </ListItem>
        <ListItem button onClick={() => { history.push('/landmarks') }}>
          <ListItemIcon><AccountBalanceOutlinedIcon /></ListItemIcon>
          <ListItemText primary="Забележителности" />
        </ListItem>
        <ListItem button onClick={() => { props.onOpenContactUs() }} >
          <ListItemIcon><CallOutlinedIcon /></ListItemIcon>
          <ListItemText primary="Контакти" />
        </ListItem>
        <ListItem button onClick={() => { window.open('https://www.google.bg/maps/place/Sunday+Guest+House+%2F+%D0%9A%D1%8A%D1%89%D0%B0+%D0%B7%D0%B0+%D0%B3%D0%BE%D1%81%D1%82%D0%B8+%D0%A1%D1%8A%D0%BD%D0%B4%D0%B5%D0%B9/@43.3879901,23.2169653,17z/data=!4m18!1m9!3m8!1s0x40ab334595555555:0x5579283d6a5f4c18!2sFamily+Hotel+Ring!5m2!4m1!1i2!8m2!3d43.3893003!4d23.2169274!3m7!1s0x40ab330ca425d40f:0x9c5a9e2241663bdc!5m2!4m1!1i2!8m2!3d43.3873325!4d23.2161702', '_blanc'); }}>
          <ListItemIcon><DirectionsOutlinedIcon /></ListItemIcon>
          <ListItemText primary="Навигация" />
        </ListItem>
      </List>
    </div>
  );
};

export default MobileMenu;