export const landmarkPics = {
  ogosta:'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/landmarks%2Fogosta.png?alt=media&token=00945bc3-ea5e-452c-b398-c58a614b102b',
  krepost:'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/landmarks%2Fkrepost.jpg?alt=media&token=00945bc3-ea5e-452c-b398-c58a614b102b',
  belogradchik:'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/landmarks%2Fbelogradchik.jpg?alt=media&token=00945bc3-ea5e-452c-b398-c58a614b102b',
  magura:'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/landmarks%2Fmagura.jpg?alt=media&token=00945bc3-ea5e-452c-b398-c58a614b102b',
  ledenika:'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/landmarks%2FLedenikaCave.jpg?alt=media&token=00945bc3-ea5e-452c-b398-c58a614b102b',
  klisurski:'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/landmarks%2Fklisurski.jpg?alt=media&token=00945bc3-ea5e-452c-b398-c58a614b102b',
  lopushanski:'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/landmarks%2Flopushanski.jpg?alt=media&token=00945bc3-ea5e-452c-b398-c58a614b102b',
  babavida:'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/landmarks%2FBabaVida.jpg?alt=media&token=00945bc3-ea5e-452c-b398-c58a614b102b'
};

export const homePics = [
  'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/home_screen_pics%2Fhome1.jpg?alt=media&token=8c120956-b5fc-41a9-b970-9b2c56aa1e39',
  'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/home_screen_pics%2Fhome2.jpg?alt=media&token=8c120956-b5fc-41a9-b970-9b2c56aa1e39'
];

const pics = [
  [
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_garden%2Fgarden1.jpg?alt=media&token=e7fba320-c620-4e63-b811-9eb4e9918cd8',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_garden%2Fgarden2.jpg?alt=media&token=e7fba320-c620-4e63-b811-9eb4e9918cd8',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_garden%2Fgarden3.jpg?alt=media&token=e7fba320-c620-4e63-b811-9eb4e9918cd8',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_garden%2Fgarden4.jpg?alt=media&token=e7fba320-c620-4e63-b811-9eb4e9918cd8',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_garden%2Fgarden5.jpg?alt=media&token=e7fba320-c620-4e63-b811-9eb4e9918cd8',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_garden%2Fgarden6.jpg?alt=media&token=e7fba320-c620-4e63-b811-9eb4e9918cd8',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_garden%2Fgarden7.jpg?alt=media&token=e7fba320-c620-4e63-b811-9eb4e9918cd8'
  ],
  [
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms1.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms2.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms3.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms4.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms5.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms6.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms7.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms8.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms9.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms10.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms11.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms12.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms13.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_rooms%2Frooms14.jpg?alt=media&token=306b8633-564d-4bb5-95fb-12a77f05894e'
  ],
  [
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_kitchen%2Fkitchen1.jpg?alt=media&token=68faa737-c37f-4584-aee5-651c5cb0d853',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_kitchen%2Fkitchen2.jpg?alt=media&token=68faa737-c37f-4584-aee5-651c5cb0d853'
  ],
  [
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_house%2Fhouse1.jpg?alt=media&token=f50b282f-22a4-4b5a-b7a6-d923cf08973b',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_house%2Fhouse2.jpg?alt=media&token=f50b282f-22a4-4b5a-b7a6-d923cf08973b',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_house%2Fhouse3.jpg?alt=media&token=f50b282f-22a4-4b5a-b7a6-d923cf08973b',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_house%2Fhouse4.jpg?alt=media&token=f50b282f-22a4-4b5a-b7a6-d923cf08973b',
    'https://firebasestorage.googleapis.com/v0/b/guest-house--sunday.appspot.com/o/gallery_house%2Fhouse5.jpg?alt=media&token=f50b282f-22a4-4b5a-b7a6-d923cf08973b'
  ],
]

export default pics