export const text = {
  intro: `Къщата се намира на 2 км. от центъра на гр. Монтана на югоизточния бряг на
язовир Огоста, с невероятна гледка към Стара Планина, връх Ком и Копрен. Къщата разполага с 4 двойни стаи, 1  единична стая и 1 апартамент с
разтегателен диван със собствени баня и голям балкон, кухненски бокс и
хладилник.`,
  parking: `Частен паркинг`,
  kitchen: `Напълно оборудвана кухня с трапезария и дневна.`,
  children: `Къщата също е оборудвана с детски креватчета, детски стол за хранене и детска площадка`,
  wifi: `Безжичен интернет във всички стаи.`,
  pool: `Гостите на къщата ще се насладят на отопляем басейн с плажна част единствено за тях.`,
  overall: `Съндей e перфектното място за отдих със своите външно барбекю с кът за хранене навън и беседка, подходяща както за уикенди, така и за семейни ваканции, за любители на риболова и планински преходи.`
}

export const landmarks = {
  text: `Монтана е един красив град, разположен на 100 км северно от София и в непосредствена близост до река Огоста.
  Градът е сгушен между подножието на Стара планина и Дунавската равнина.
  Насладете се на оригиналната култура на местното население, открийте няколко исторически забележителности и се докоснете до местните чудеса на природата. Районът е богат на красиви забележителности, стари църкви и манастири. Реновираните паркове предлагат възможности за отдих на открито и спортни дейности.`,
  all: ['ogosta', 'krepost', 'belogradchik', 'magura', 'ledenika', 'klisurski', 'lopushanski', 'babavida'],
  ogosta: {
    Heading: `Язовир Огоста и язовирна стена`,
    Text: `Язовир Огоста е вторият по големина язовир в България и на Балканския полуостров. Язовирът е разположен 600м югозападно от града и само на 50м от къщата. В близост до къщата е и огромната язовирна стена, където можете да се разходите и насладите на прекрасната панорамна гледка към язовира и Стара планина.`
  },
  krepost: {
    Heading: `Кастра Ад Монтанезиум`,
    Text: `Кастра ад Монтанезиум е древна римска крепост разположена на 2 км от къщата, на хълма Калето, запазен като паметник на древната история. Днес крепостта е със свободен достъп и е реставрирана частично.`
  },
  belogradchik: {
    Heading: `Белоградчишки Скали`,
    Text: `Белоградчишките скали са естествено образуван пясъчен скален комплекс, състоящ се от изключително разнообразни по размер и форма образувания. Някои от формациите са по-високи от 100 метра. Този природен феномен е с глобално значение от геологична гледна точка, номиниран от ЮНЕСКО за "Новите 7 чудеса на природата". През летните месеци можете да се радвате на живи оперни спектакли под звездите. Скалите са на 66 км от Къщата.`
  },
  magura: {
    Heading: `Пещера Магура`,
    Text: `Пещерата Магурата се смята, че е на повече от 15 милиона години с целогодишна постоянна температура от 12С. В пещерата има стенописи, датиращи от Палеолита, Неолита и Бронзовата епоха. Пещерата е на 85 км от къщата.`
  },
  ledenika: {
    Heading: `Пещера Леденика`,
    Text: `Леденика е величествена пещера в северозападните части на Стара планина, близо до град Враца. Пещерата е около 300м дълга и се състои от десет отделни зали, в които има изобилие от галерии и впечатляващи карстови образувания. Пещерата се намира на 56 км от къщата.`
  },
  klisurski: {
    Heading: `Клисурски Манастир`,
    Text: `Клисурският манастир "Св. Кирил и Методий" датира от 13-ти век и се намира в полите на Стара планина близо до град Вършец. Там бихте могли да се насладите на великолепна архитектура, да се срещнете с монаси и да опитате пресни местни продукти.`
  },
  lopushanski: {
    Heading: `Лопушански Манастир`,
    Text: `Живописният Лопушански манастир "Свети Йоан Предтеча" е чудесна дестинация за еднодневна екскурзия. Той се намира само на 20,6 км от къщата.`
  },
  babavida: {
    Heading: `Крепост Баба Вида`,
    Text: `Крепостта Баба Вида датира от 12-ти век и е разположена на брега на река Дунав в град Видин. В наши дни крепостта функционира като музей и се намира на 105 км от къщата.`
  },
}

export const modals = {
  contactUs: {
    phone: `+359 88 905 6552`,
    email: `sundayyy93@gmail.com`,
  },
  rules: {
    intro: `ЗА ПОЛЗВАНЕ НА ТУРИСТИЧЕСКИ ОБЕКТИ: КЪЩА ЗА ГОСТИ „SUNDAY“ гр. Монтана`,
    please: `Моля, внимателно прочетете тези вътрешни правила за ползване на къща за гости „Sunday“ 
    преди да направите резервация. Спазването на правилата ще подсигури Вашата приятна и безпроблемна почивка при нас.`,
    reservationsTitle: `Настаняването и освобождаването:`,
    reservationsIn: ` - Настаняване от 14:00 до 20:00 ч.`,
    reservationsOut: ` - Освобождаване до 12 часа на обяд`,
    reservations: `Възможност за по-ранно настаняване и/или по-късно освобождаване на стаите – след допълнително уточняване при запитване.`,
    rulesTitle: `Вътрешен ред:`,
    rules1: `1. Забранява се влизане и нощуване на лица в къщата, които не са регистрирани по надлежния ред.`,
    rules2: `2. Забранява се разместването на мебели и имущество в стаите и помещенията на къщита (без съгласуване със собственика на къщата).`,
    rules3: `3. Забранява се изнасянето на всякакъв вид имущество (хавлии, кърпи, инвентар, посуда и др.) по време на престоя на гостите.`,
    rules4: `4. При настаняване от всички наши гости се изисква документ за самоличност (лична карта);
  (паспорт) и други, съобразно изискванията на Закона за туризма /ЗТ/, чл. 116, (1) Лицата,
  извършващи хотелиерство, водят регистър за настанените туристи със съдържание на данните,
  утвърдени от министъра на туризма, при реда и условията на Закона за защита на личните
  данни /ЗЗЛД/. При отказ от Ваша страна за вписване на задължителните лични данни, няма да
  Ви се предостави съответната услуга за настаняване в къща за гости „ Sunday ”`,
    rules5: `5. На територията на туристическия обект не се допускат домашни любимци.`,
    rules6: `6. Гостите нямат право да внасят и използват електронагревателни (отоплителни) уреди и съоръжения по време на престоя си в къщите.`,
    rules7: `7. Пушенето в къща за гости „Sunday“ е абсолютно забранено. Допустимо е единствено на терасата и в двора на обектите.`,
    rules8: `8. Всички лица настанени в туристическия обект са длъжни да опазват материално-
  техническата база, да не предизвикват умишлено вреди, или такива настъпили по
  непредпазливост от тяхна страна, да използват всички технически съоръжения само и
  единствено според тяхното предназначение. При напускане на обекта клиентите са длъжни да
  го оставят в състоянието, в което са го заварили при настаняването им. За всички нанесени
  материални вреди, действия или бездействия, които водят до унищожаването на вещи и други
  на територията на туристическия обект, клиентите носят цялата отговорност, като заплащат
  стойността на увредените или унищожени материално-технически съоръжения. Тези от тях,
  които подлежат на ремонт, същият се осъществява за сметка на клиентите. При отказ от
  заплащане от страна на лицата, които са нанесли щети върху материално-техническата база
  на територията на туристическия обект, ще бъде потърсена отговорност по реда и условията на
  Закона за задълженията и договорите /ЗЗД/, чл. 82, обезщетението обхваща претърпяната
  загуба и пропуснатата полза, доколкото те са пряка и непосредствена последица от
  неизпълнението и са могли да бъдат предвидени при пораждане на задължението. Но ако
  длъжникът е бил недобросъвестен, той отговаря за всички преки и непосредствени вреди.
  `,
    rules9: `9. При напускане гостите са длъжни лично да предадат ключовете за съответните стаи на администратора на къщата.`,
    rules10: `10. Незаконни или неморални дейности – хазарт, проституция, употреба на наркотици,
  притежание или употреба на пиротехнически средства или опасни товари, притежание или
  употреба на огнестрелни и други оръжия – са абсолютно забранени!`,
    rules11: ` 11. При нарушаване на обществения ред, в това число и нарушаване на забраната за силен
  шум в часовия диапазон между 14:00 – 16:00 часа и между 23:00 и 08:00 часа в следствие, на
  което бъде подаден сигнал към органите на реда, нарушителите – гости на къща за гости
  „Sunday“, поемат цялата отговорност за причинените от нарушаването на обществения ред
  вреди за къща за гости „Sunday“. Ако органите на реда наложат санкции, глоби, актове и/или
  други наказания, предвидени от българското законодателство, те се покриват изцяло от
  гостите, отговорни за установените нарушения.`,
    rules12: `12. Собственикът на къща за гости „Sunday“ не носи отговорност за произшествия с децата на
  гостите, настъпили както в рамките на туристическия обект, така и извън него. Доброто здраве и
  състояние на децата са отговорност на техните родители!`,
    rules13: `13. При констатиране на груби нарушения на вътрешния ред и не спазване на този правилник,
  собственикът на къща за гости „Sunday “, има право да прекрати правоотношенията си с гостите
  и съответно престоя им .
  Начин на резервиране:
  Резервация по телефона или имейла, посочен в сайта
  Резервация на място в къщата
  Резервация чрез онлайн платформа за настаняване
  Резервацията се счита за потвърдена след заплащане на капаро в размер на 50% в срок до 3
  работни дни от извършване на резервацията.`,
    paymentsTitle: `Плащанията се извършват по един от следните начини:`,
    paymentsText: `/избран от гостите, след предварително съгласуване с представител на къща за гости „Sunday “/`,
    payments1: `- по банков път, чрез електронно банкиране или`,
    payments2: `- на място в къща за гости „Sunday“ – в брой`,
    valuablesTitle: `Ценности и сигурност:`,
    valuablesText: `Всички ценности и лични вещи на гостите, внесени на територията на къща за гости „Sunday“,
са отговорност на съответния гост.При отворени/ отключени прозорци и врати по време на
периода им на настаняване, независимо дали гостите са на територията на къща за гости
„Sunday“ или извън нея(на разходка, плаж, пикник и др.), отговорността при загуба или кражба
е на съответния гост.`,
    cancellationTitle: `Прекратяване / анулиране на резервация:`,
    cancellationText: `Прекратяването / анулирането на потвърдена резервация се извършва в срок не по кратък от 7
дни преди датата на пристигане на гостите.За тази цел е необходимо да изпратят писмено
уведомление за отказ на: sundayyy93@gmail.com.В случай, че прекратяването / анулирането е
направено след този срок, гостите дължат неустойка в размер на 50 % от цялата стойност на
резервацията.Същото важи и при неявяване на направена резервация.`,
    acceptance: `Приемане на общите условия:
С потвърждаването на резервацията клиентите заявяват, че са прочели и разбрали
вътрешните правила за ползване на къща за гости „Sunday“ и са съгласни с тях!`,
  },

  booking: {

  }
};
