import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { withTheme } from '@material-ui/core/styles';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

// Pictures
import pics from '../data/pics.js'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const TabLabels = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Gallery = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root} style={{ backgroundColor: props.theme.palette.secondary.main }}>
      <h1 style={{ paddingLeft: '5%', paddingTop: '2%' }}>Галерия</h1>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Двор" {...TabLabels(1)} />
          <Tab label="Стаи" {...TabLabels(3)} />
          <Tab label="Кухня" {...TabLabels(2)} />
          <Tab label="Къща" {...TabLabels(0)} />
        </Tabs>
      </AppBar>

      {pics.map((picsArr, arrIndex) => {
        return (
          <TabPanel value={value} index={arrIndex}>
            <Splide>
              {picsArr.map(pic => {
                return (
                  <SplideSlide>
                    <img src={pic} style={{ width: '100%' }} alt="Gallery pic" />
                  </SplideSlide>
                )
              })}
            </Splide>
          </TabPanel>
        )
      })}
    </div>
  );
};

export default withTheme(Gallery);
