import { makeStyles, useScrollTrigger, Zoom } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(10),
  },
}));

const ScrollTop = (props) => {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = document.querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={(event) => handleClick(event)} role="presentation" className={classes.root}>
        {props.children}
      </div>
    </Zoom>
  );
};

export default ScrollTop;
