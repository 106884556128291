import { Grid, makeStyles, withTheme } from '@material-ui/core';
import { Typography } from '@material-ui/core';

// Pictures
import { landmarkPics } from '../../data/pics.js'

// Text
import { landmarks } from '../../data/text';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    [theme.breakpoints.down(1080)]: {
      fontSize: '1.5em'
    },
    [theme.breakpoints.down(770)]: {
      fontSize: '1em',
      paddingTop: '8%'
    },
    [theme.breakpoints.down(550)]: {
      fontSize: '.8em',
      paddingTop: '10%'
    },
  },
  pageText: {
    [theme.breakpoints.down(1080)]: {
      fontSize: '.8em'
    },
    [theme.breakpoints.down(770)]: {
      fontSize: '.7em'
    },
    [theme.breakpoints.down(550)]: {
      fontSize: '.6em'
    },
  },
  title: {
    [theme.breakpoints.down(1400)]: {
      fontSize: '1.5em'
    },
    [theme.breakpoints.down(1080)]: {
      fontSize: '1.2em'
    },
    [theme.breakpoints.down(770)]: {
      fontSize: '.9em',
      paddingTop: '8%'
    },
    [theme.breakpoints.down(550)]: {
      fontSize: '.7em',
      paddingTop: '10%'
    },
  },
  text: {
    [theme.breakpoints.down(1400)]: {
      fontSize: '.8em'
    },
    [theme.breakpoints.down(1080)]: {
      fontSize: '.7em'
    },
    [theme.breakpoints.down(770)]: {
      fontSize: '.6em'
    },
    [theme.breakpoints.down(550)]: {
      fontSize: '.5em'
    },
  },
  pageTextWrapper: {
    [theme.breakpoints.up(1024)]: {
      width: '70%', paddingLeft: '15%'
    },
    [theme.breakpoints.down(1024)]: {
      width: '80%', paddingLeft: '10%'
    },
  }
}));


const Landmarks = (props) => {
  const classes = useStyles();

  return (
    <>
      <div style={{ marginTop: '7%' }}>
        <div className={classes.pageTextWrapper} style={{ textAlign: 'justify', marginBottom: '5%' }} id="back-to-top-anchor">
          <h1 className={classes.pageTitle} style={{ textAlign: 'center' }}>Местни Забележителности и Дестинации в Близост</h1>

          <p className={classes.pageText}>{landmarks.text}</p>
        </div>
        {landmarks.all.map((element, index) =>
          index % 2 === 0
            ? (
              <div id={`${index}color`} style={{ marginBottom: '5%', backgroundColor: props.theme.palette.secondary.main, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                <div id={`${index}wrapper`} style={{ width: '80%', paddingLeft: '10%' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <Typography className={classes.title} variant="h5">
                        {landmarks[element].Heading}
                      </Typography>

                      <Typography className={classes.text} variant="body1">
                        {landmarks[element].Text}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <img style={{ width: '80%' }} src={landmarkPics[element]} alt={element} />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )
            : (
              <div id={`${index}color`} style={{ marginBottom: '5%' }}>
                <div id={`${index}wrapper`} style={{ width: '80%', paddingLeft: '10%' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <img style={{ width: '80%' }} src={landmarkPics[element]} alt={element} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.title} variant="h5">
                        {landmarks[element].Heading}
                      </Typography>

                      <Typography className={classes.text} variant="body1">
                        {landmarks[element].Text}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )
        )}
      </div>
    </>

  );
};

export default withTheme(Landmarks);
