import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import Router from './components/Router';
import firebase from 'firebase';
import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#e64a19',
    },
    secondary: {
      main: '#ffc107',
    },
  },
});

var firebaseConfig = {
  apiKey: "AIzaSyCMtg1YK1D0g6lnSUl4Zi62vgJU5_MchQg",
  authDomain: "guest-house--sunday.firebaseapp.com",
  projectId: "guest-house--sunday",
  storageBucket: "guest-house--sunday.appspot.com",
  messagingSenderId: "566202494278",
  appId: "1:566202494278:web:ec1fc783c3928a7f238947",
  measurementId: "G-ZH1BRB2CVR"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.storage().ref('images/home1.jpg').getDownloadURL().then( res => console.log(res))


export const functions = firebase.functions();

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider >
  );
};

export default App;
