import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Layout from './Layout/Layout';

// components
import Home from './views/Home';
import Landmarks from './views/Landmarks';
import Booking from './views/Booking';

const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Redirect path="/" exact to="/home" />

          <Route path="/home" exact component={Home} />
          <Route path="/landmarks" exact component={Landmarks} />
          <Route path="/booking" exact component={Booking} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default Router;
